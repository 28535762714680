@import "mixins";

.#{$prefix}item {
  @include item-configs;
}

// Item aplicado em link/botão
a.#{$prefix}item {
  color: var(--interactive);
  @include hover;
  @include active;
}

button.#{$prefix}item {
  background-color: transparent;
  color: var(--color);
  @include focus;
  @include hover("color");
  @include active("color");
}
