/// Aplica hover: feedback ao colocar o cursor no elemento
/// @param {String} $color ["interactive"] - Cor do hover **(Opcional)**
/// @example scss - Uso prático
///  a {
///    @include hover;
///  }
@mixin hover($color: "interactive") {
  &:not(:disabled) {
    &:hover {
      background-image: linear-gradient(
        rgba(var(--#{$color}-rgb), var(--hover)),
        rgba(var(--#{$color}-rgb), var(--hover))
      );
    }
  }
}
