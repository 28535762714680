@mixin divider-configs {
  @include divider-tokens;
  @include divider-default;
  @include divider-content;
  @include divider-vertical;
  @include divider-inverted;
  @include divider-dashed;
  @include divider-thickness;
}

@mixin divider-tokens {
  --divider-size: var(--surface-width-sm);
}

@mixin divider-default {
  border-color: var(--border-color);
  border-style: solid;
  border-width: 0;
  border-top-width: var(--divider-size);
  display: block;
}

@mixin divider-content {
  &.content {
    align-items: center;
    border: 0;
    display: flex;
    justify-content: center;

    &::after,
    &::before {
      border-color: var(--border-color);
      border-style: solid;
      border-width: 0;
      border-top-width: var(--divider-size);
      content: "";
      flex: 1;
    }

    &::after {
      margin-left: var(--spacing-scale-base);
    }

    &::before {
      margin-right: var(--spacing-scale-base);
    }
  }
}

@mixin divider-vertical {
  &.vertical {
    align-self: stretch;
    border-right-width: var(--divider-size);
    border-top-width: 0;

    &.content {
      flex-direction: column;

      &::after,
      &::before {
        border-right-width: var(--divider-size);
        border-top-width: 0;
        margin: 0;
      }
    }
  }
}

@mixin divider-inverted {
  &.inverted,
  &.dark-mode {
    --border-color: var(--pure-0);
  }
}

@mixin divider-dashed {
  &.dashed {
    border-style: dashed;
  }
}

@mixin divider-thickness {
  @each $thickness in "sm", "md", "lg" {
    &.#{$thickness} {
      --divider-size: var(--surface-width-#{$thickness});
    }
  }
}
